import {
 Accordion,
 AccordionDetails,
 AccordionSummary,
 Box,
 Container,
 Grid,
 List,
 ListItem,
 ListItemIcon,
 ListItemText,
 Typography,
 Button
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import CommonContactFrom from "./CommonContactFrom";
const Sco = () => {
 return (
  <>
   <Box>
    <img src="../source/seo.jpg" alt="" width="100%" />
   </Box>
   {/* ------box end */}
   <Box sx={{ paddingTop: "3%", paddingBottom: "3%" }}>
    <Container>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography variant="h5" sx={{ padding: "2%" }}>
        Digital Marketing services that provide long-term results to increase
        traffic, sales and conversions
       </Typography>
       <Typography variant="body1" sx={{ padding: "2%", fontWeight: "200" }}>
        Digital marketing is the process of marketing the product or services
        through Internet (www) using organic SEO, display advertising, mobile
        phones, social media and in other digital channels. It plays a prominent
        role in building brand promotion, customer experience and sales
        conversions. To improve your visibility online in search engine results
        page (SERP), you need a digital marketing partner who facilitates in
        framing the right digital marketing strategies that convert every
        visitor into a customer.
       </Typography>
       <Typography variant="body1" sx={{ padding: "2%", fontWeight: "200" }}>
        We understand our customer requirements and offer services that make
        your brand visible on social media, email, and search results. Our team
        of digital marketers provides a one-stop solution that comprises of
        content optimization, targeted keyword inclusion, PPC promotion, social
        marketing, and ROI analytics.
       </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "2%" }}>
       <img src="../source/about-seo-img.jpg" alt="" />
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <Box>
    <Container>
     <img src="../source/digital.jpg" alt="" width="100%" />
    </Container>
    <Container>
     <Typography sx={{ padding: "2%", fontWeight: "200" }}>
      Digital Proficiency is the right way to describe we best. The social geeks
      are pioneers in implementing brands digitally and promoting them virally.
      Our innovative Minds strive to give a custom made solution to each client
      that gives them a supreme experience in digital solutions. We take a
      detailed study on the vision, mission and their value of the brand and
      business to give the desired reach in digital marketing.
     </Typography>
     <Typography sx={{ padding: "2%", fontWeight: "200" }}>
      From Designing a Basic Website,Ecommerce website, Web application
      development to online promotions and marketing, we are a full service
      provider in best digital marketing services.Our range of services include
     </Typography>
    </Container>
   </Box>
   {/* ------box end */}
   <Box sx={{ backgroundColor: "#f0efef", paddingTop: "5%",paddingBottom:"5%"}}>
    <Container>
    <Typography variant="h4" sx={{ textAlign: "center",paddingBottom:"5%" }}>
      Service Highlights
     </Typography>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Container sx={{padding:'3%'}}>
       <Accordion>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="Acc1"
         id="Acc1"
        >
         <Typography
          variant="h6"
          //    sx={{ color: expanded === "Acc1" ? "#0a89ff" : "inherit" }}
         >
          <span style={{ color: "#0a89ff" }}>01.</span> ORGANIC DIGITAL MARKETING
         </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <List>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Search Engine Optimisation" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Online Reputation Management" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Social Media Maintenance" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Promotional Videos" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Content Marketing" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Public Relations" />
          </ListItem>
         </List>
        </AccordionDetails>
       </Accordion>
       </Container>
       <Container sx={{padding:'3%'}}>
       <Accordion>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="Acc1"
         id="Acc1"
        >
         <Typography
          variant="h6"
          //    sx={{ color: expanded === "Acc1" ? "#0a89ff" : "inherit" }}
         >
          <span style={{ color: "#0a89ff" }}>02.</span> GENERIC DIGITAL MARKETING
         </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <List>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Email Marketing" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Whats app Marketing" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Chatbots" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Ad Promotions" />
          </ListItem>
         </List>
        </AccordionDetails>
       </Accordion>
       </Container>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} >
      <Container sx={{padding:'3%'}}>
       <Accordion>
        <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="Acc1"
         id="Acc1"
        >
         <Typography
          variant="h6"
          //    sx={{ color: expanded === "Acc1" ? "#0a89ff" : "inherit" }}
         >
          <span style={{ color: "#0a89ff" }}>03.</span>AD CAMPAIGNS/ PAID
         </Typography>
        </AccordionSummary>
        <AccordionDetails>
         <List>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Google Ad Words Pay per click" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Retargeting" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Social Media Ad Campaigns" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Video Promotions" />
          </ListItem>
          <ListItem>
           <ListItemIcon>
            <StarIcon />
           </ListItemIcon>
           <ListItemText primary="Influencer Marketing" />
          </ListItem>
         </List>
        </AccordionDetails>
       </Accordion>
       </Container>
      </Grid>
     </Grid>
    </Container>
   </Box>
    {/* ------box end */}
   <Box sx={{ padding: "5%", backgroundColor: "#f9fcff" }}>
    <Container>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography variant="h4">Portfolio </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "end" }}>
       <Button variant="outlined">View web Portfolio</Button>
       <br />
       <span>CommingSoon....</span>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <CommonContactFrom />
  </>
 );
};

export default Sco;

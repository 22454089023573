import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import React, { useState } from "react";
import CountUp from "react-countup";

const Aboutus = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(./source/about-banner.jpg)`,
          backgroundPosition: "center",
          padding: "0px",
        }}
      >
        <Container>
          <Grid
            sx={{
              paddingTop: "15%",
              paddingBottom: "15%",
              color: "white",
              width: "60%",
              textAlign: "justify",
            }}
          >
            {/* <Typography variant='h5' sx={{ paddingBottom: '2%' }}>
                            About Pixelize
                        </Typography> */}
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", fontSize: "1.2rem" }}
            >
              At Pixelize, we’re passionate about turning ideas into reality.
              Our team of skilled developers, designers, and tech enthusiasts
              collaborates to create innovative solutions that empower
              businesses and individuals. Whether it’s crafting intuitive mobile
              apps, building robust software systems, or providing cutting-edge
              IT consulting, we’re committed to excellence.
            </Typography>
          </Grid>
        </Container>
      </Box>
      {/* box end */}
      <Box
        sx={{
          backgroundColor: "#ef5e1f",
          padding: "2%",
          textAlign: "center",
          color: "white",
          fontSize: "1.5rem",
        }}
      >
        Technology | Consulting | Outsourcing
      </Box>
      {/* box end */}
      <Box>
        <Container>
          <Grid container>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ paddingTop: "2%", paddingBottom: "2%" }}
            >
              <Typography
                variant="h6"
                sx={{ paddingTop: "2%", paddingBottom: "2%" }}
              >
                Transforming Business with
                <br />
                Digital Transformation and <br />
                Technology Revolution
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontSize: "1.2rem",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                Pixelize Technologies provides end-to-end technology solutions
                that streamline your digital innovation and technical
                transformation journey.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "300",
                  fontSize: "1.2rem",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                We refine our technology services to gain a deep understanding
                of our clients’ business systems. Based on this understanding,
                we recommend and seamlessly implement the best solutions
                available in the market.
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
              }}
            >
              <img
                src="./source/aboutus.png"
                width={"70%"}
                height={"auto"}
                alt=""
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box end */}
      <Box
        style={{
          backgroundImage: `url(./source/counters-bg.jpg)`,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "center cnter",
        }}
      >
        <Container>
          <Grid container>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ padding: "10% 0 10%" }}
            >
              <Typography variant="h6" color="#FFEB3B">
                Pixelize Technologies (India) Pvt. Ltd
              </Typography>
              <Typography variant="h3" color="#ffffff" paddingTop={"5%"}>
                Top engineers <br /> dedicated to your <br /> success
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ padding: "10% 0 10%" }}
            >
              <Container>
                <Grid container sx={{ color: "white" }}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Divider
                      variant="left"
                      sx={{
                        width: "80%",
                        backgroundColor: "white",
                        paddingTop: "2px",
                        marginBottom: "3%",
                      }}
                    />
                    <Typography sx={{ fontSize: "2.5rem" }}>
                      <CountUp start={0} end={5} duration={5} suffix="+ " />
                    </Typography>
                    <Typography sx={{ fontSize: "0.7 rem" }}>
                      Satisfied clients
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Divider
                      variant="left"
                      sx={{
                        width: "80%",
                        backgroundColor: "white",
                        paddingTop: "2px",
                        marginBottom: "3%",
                      }}
                    />
                    <Typography sx={{ fontSize: "2.5rem" }}>
                      <CountUp start={0} end={12} duration={5} suffix="+ " />
                    </Typography>
                    <Typography sx={{ fontSize: "0.7 rem" }}>
                      Projects completed
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
              <Container sx={{ marginTop: "5%" }}>
                <Grid container sx={{ color: "white" }}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Divider
                      variant="left"
                      sx={{
                        width: "80%",
                        backgroundColor: "white",
                        paddingTop: "2px",
                        marginBottom: "3%",
                      }}
                    />
                    <Typography sx={{ fontSize: "2.5rem" }}>
                      <CountUp start={0} end={20} duration={5} suffix="K+ " />
                    </Typography>
                    <Typography sx={{ fontSize: "0.7 rem" }}>
                      Lines of code
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Divider
                      variant="left"
                      sx={{
                        width: "80%",
                        backgroundColor: "white",
                        paddingTop: "2px",
                        marginBottom: "3%",
                      }}
                    />
                    <Typography sx={{ fontSize: "2.5rem" }}>
                      <CountUp start={0} end={50} duration={5} suffix="+ " />
                    </Typography>
                    <Typography sx={{ fontSize: "0.7 rem" }}>
                      IT Professionals
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box end */}
      <Box>
        <Container>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12} padding={"5% 0 5%"}>
              <Typography variant="h5" padding={"3% 0 3%"}>
                Delighted customers are the <br /> cornerstone of successful
                business.
              </Typography>
              <Typography
                variant="body1"
                padding={"3% 0 3%"}
                textAlign={"justify"}
                sx={{ fontSize: "1rem", fontWeight: "200" }}
              >
                Pixelize Technologies, headquartered in Tiruppur, South India,
                is a globally reputed web design and development firm. Our
                comprehensive services include IT outsourcing, SEO, SEM,
                CMS-based website development, e-commerce solutions, website
                design, digital branding, and mobile and web-based app
                development. With a team fueled by ambition, zeal, and a clear
                vision, we strive to streamline your digital innovation and
                technical transformation journey.
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "5%" }}>
              <Accordion expanded={true} onChange={handleChange("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon />}
                  aria-controls="panel1-mission"
                  id="panel1-mission"
                >
                  <Typography>Our Mission</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: "1 rem", fontWeight: "300" }}>
                    Our mission is to cultivate long-term relationships with our
                    clients by delivering innovative, high-quality, and
                    cost-effective solutions and services.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={true} onChange={handleChange("panel2")}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon />}
                  aria-controls="panel1-vision"
                  id="panel1-vision"
                >
                  <Typography>Our Vision</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: "1 rem", fontWeight: "300" }}>
                    We are deeply committed to creating high-end, innovative,
                    and quality services and solutions for our clients.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Aboutus;

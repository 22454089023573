import React, { useRef } from "react";
import { Box,Button,Container,Grid,TextField,Typography,} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import emailjs from '@emailjs/browser';

const CommonContactFrom = () => {
    const form = useRef();
    const sendMail =(e)=>{
        e.preventDefault()
        emailjs.sendForm('service_7kxwg2e', 'template_gd7cvxu', form.current, '5BuCsvVRMhOJbwkaG')
      .then((result) => {
          console.log("Email Send",result.text);
      }, (error) => {
          console.log("Email Send",error.text);
      });
      e.target.reset()
    }
 return (
  <>
   <Box sx={{ backgroundColor: "#005aff21", padding: "5%" }}>
    <Container>
     <Typography variant="h4" style={{ textAlign: "center" }}>
      Let's talk About your business
     </Typography>
     <form ref={form} onSubmit={sendMail}>
     <Grid container>
      <Grid item xs={12} lg={3} sm={12} md={3} sx={{ padding: "2%" }}>
       <TextField
        fullWidth
        id="FirstName"
        label="FirstName"
        variant="standard"
        name="name"
        type="text"
       />
      </Grid>
      <Grid item xs={12} lg={3} sm={12} md={3} sx={{ padding: "2%" }}>
       <TextField fullWidth id="LastName" label="lastName" variant="standard"  name="lname"
        type="text"/>
      </Grid>
      <Grid item xs={12} lg={6} sm={12} md={6} sx={{ padding: "2%" }}>
       <TextField
        fullWidth
        id="Organization"
        label="Organization"
        variant="standard"
        name="Commpany"
        type="text"
       />
      </Grid>
      <Grid item xs={12} lg={6} sm={12} md={6} sx={{ padding: "2%" }}>
       <TextField fullWidth id="Email" label="Email" variant="standard"  name="email"
        type="email" />
      </Grid>
      <Grid item xs={12} lg={6} sm={12} md={6} sx={{ padding: "2%" }}>
       <TextField fullWidth id="Phone" label="Phone" variant="standard" name="phone"
        type="number"/>
      </Grid>
      <Grid item xs={12} lg={12} sm={12} md={12} sx={{ padding: "2%" }}>
       <TextField
        fullWidth
        id="Description"
        label="Message or Project Description"
        variant="standard"
        name="massage"
        type="text"
       />
      </Grid>
      <Grid
       item
       xs={12}
       lg={12}
       sm={12}
       md={12}
       sx={{ padding: "2%", textAlign: "center" }}
      >
       <Button variant="contained" endIcon={<SendIcon />} type="submit" value="Send">
        Send
       </Button>
      </Grid>
     </Grid>
     </form>
    </Container>
   </Box>
  </>
 );
};

export default CommonContactFrom;

import React from "react";
import {
    AppBar,
    Container,
    Toolbar,
    Typography,
    Box,
    IconButton,
    Menu,
    Button,
    MenuItem,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";
// import AdbIcon from '@mui/icons-material/Adb';

// const pages = ['home', 'about', 'contact'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };
    return (
        <>
            <AppBar position="static">
                <Container maxWidth="md">
                    <Toolbar>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: "none", md: "flex" },
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            {/* Pixelize */}
                            <img src="/plogo.png" alt="logo" width="10%" />
                            <Typography style={{'marginTop' : 'auto'}}>IXELIZE </Typography>
                            
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                {/* {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center"> 
                                        <Link href={`/${page}`} style={{textDecoration:"none",color:"blue"}}>{page}</Link></Typography>
                                    </MenuItem>
                                ))} */}
                                <MenuItem key="home" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to="/" style={{ textDecoration: "none", color: "blue" }}>
                                            Home
                                        </Link>
                                    </Typography>
                                </MenuItem>
                                <MenuItem key="about" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to="/about" style={{ textDecoration: "none", color: "blue" }}>
                                            Aboutus
                                        </Link>
                                    </Typography>
                                </MenuItem>
                                {/* <MenuItem key="contact" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to="/contact" style={{ textDecoration: "none", color: "blue" }}>
                                            Contacts
                                        </Link>
                                    </Typography>
                                </MenuItem> */}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: "flex", md: "none" },
                                flexGrow: 1,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            PIXELIZE
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                                justifyContent: "flex-end",
                            }}
                        >
                            <Link style={{ textDecoration: "none", color: "white" }} to="/">
                                <Button
                                    key="home"
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block", px: "18px" }}
                                >
                                    Home
                                </Button>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/about">
                                <Button
                                    key="about"
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block", px: "18px" }}
                                >
                                    Aboutus
                                </Button>
                            </Link>
                            {/* <Link style={{ textDecoration: "none", color: "white" }} to="/contact">
                                <Button
                                    key="contact"
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block", px: "18px" }}
                                >
                                    Contact
                                </Button>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "white" }} to="/career">
                                <Button
                                    key="contact"
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block", px: "18px" }}
                                >
                                    Career
                                </Button>
                            </Link> */}
                        </Box>

                        {/* <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> */}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Header;

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/system";
import ReactWhatsapp from "react-whatsapp";
import { Link } from "react-router-dom";

const useStyles = styled(() => ({
  img: {
    transition: "transform 0.2s ease-in-out",
    backgroundColor: "red",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  tes: {
    color: "red",
  },
}));
const ColoredText = styled("span")({
  color: "#0a89ff", // Replace 'red' with the desired color
});

const HomeContent = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        maxWidth="100%"
        style={{ position: "relative" }}
        className={classes.tes}
      >
        <img
          src="./source/home-banner.webp"
          alt=""
          width={"100%"}
          height={"450px"}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#fefefedb",
            height: "100%",
          }}
        >
          <Container>
            <Grid container spacing={2}>
              <Slide in direction="right" timeout={3500}>
                <Grid item xs={12} lg={6} sm={12} md={6}>
                  <Typography
                    variant="h5"
                    sx={{
                      pt: { xs: 2, sm: 2, md: 5, lg: 5 },
                    }}
                  >
                    Experience Dynamic and Futuristic <br /> Web App Development
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      pt: { xs: 2, sm: 2, md: 5, lg: 5 },
                      lineHeight: { md: 2, lg: 2 },
                    }}
                    align="justify"
                    color="textSecondary"
                    gutterBottom
                  >
                    As a leading development and consulting partner, we
                    specialize in creating high-performing and complex user
                    interfaces using React, Next.js, React Native, Android and
                    iOS. Our passionate and experienced team of developers
                    excels at integrating these technologies to build intuitive
                    solutions that meet your unique business needs across web
                    and mobile platforms.
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    <ReactWhatsapp
                      number="+919344335333"
                      message="Hello there! I would like to know more about your services."
                      style={{ border: "none" }}
                    >
                      <Button variant="outlined" color="primary">
                        {" "}
                        GET STARTED{" "}
                      </Button>
                    </ReactWhatsapp>
                  </Typography>
                </Grid>
              </Slide>
              <Slide in direction="left" timeout={1000}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={12}
                  md={6}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  <img
                    src="./source/coder.webp"
                    alt=""
                    style={{ marginTop: "8px" }}
                    width={"100%"}
                  />
                </Grid>
              </Slide>
            </Grid>
          </Container>
        </Box>
      </Box>
      {/* -------------box end----------- */}
      <Box>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{
              pt: { xs: 2, sm: 2, md: 10, lg: 10 },
            }}
          >
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <Typography variant="h4" color="text.disabled">
                Our <ColoredText>Services</ColoredText>
              </Typography>
              <Divider
                variant="left"
                style={{
                  width: "35%",
                  backgroundColor: "#0a89ff",
                  paddingTop: "2px",
                  margin: "3px",
                }}
              />
              <Typography variant="p" color="textSecondary">
                This is what we do best
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{
                  pt: { xs: 2, sm: 2, md: 5, lg: 5 },
                  lineHeight: { md: 2, lg: 2 },
                }}
                align="justify"
                color="textSecondary"
                gutterBottom
              >
                Our team of skilled designers understands the requirements of
                our clients as a dynamic solutions company. In addition to
                creating online presences, we create custom designs that
                contribute to the success of our clients' businesses. We offer
                affordable design and development services to both startups and
                established companies.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6} sm={12} md={6}>
              <Slide in direction="top" timeout={3000}>
                <Typography
                  variant="body1"
                  sx={{
                    pt: { xs: 2, sm: 2, md: 5, lg: 5 },
                    lineHeight: { md: 2, lg: 2 },
                  }}
                  align="justify"
                  color="textSecondary"
                  gutterBottom
                  wordWrap="break-word"
                >
                  {/* <img src='./source/soft.png' alt=''  width={"70%"} /> */}
                  <Paper>
                    <img
                      src="./source/react js.png"
                      alt="ReactJS"
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        width: "25%",
                      }}
                    />
                    <img
                      src="./source/html -css.png"
                      alt="html -css"
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        width: "21%",
                      }}
                    />
                    <img
                      src="./source/nodejs.png"
                      alt="nodejs"
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        width: "25%",
                      }}
                    />
                    <img
                      src="./source/mongodb.jpg"
                      alt="mongodb"
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        width: "20%",
                      }}
                    />
                    <img
                      src="./source/ts_js.jpg"
                      alt="Typescript"
                      style={{
                        marginRight: "31px",
                        marginLeft: "21px",
                        width: "20%",
                      }}
                    />
                    <img
                      src="./source/laravel.png"
                      alt="laravel"
                      style={{
                        marginRight: "8px",
                        marginLeft: "10px",
                        width: "14%",
                      }}
                    />
                    <img
                      src="./source/nextjs.jpg"
                      alt="nextjs"
                      style={{
                        marginRight: "0px",
                        marginLeft: "25px",
                        width: "25%",
                      }}
                    />
                    <img
                      src="./source/vue js.jpg"
                      alt="vue js"
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        width: "22%",
                      }}
                    />
                  </Paper>
                </Typography>
              </Slide>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* -------------box end----------- */}
      <Box>
        <Container>
          <Box
            sx={{
              display: "grid",
              gap: "20px",
              gridTemplateColumns: {
                md: "repeat(4, 1fr)",
                lg: "repeat(4, 1fr)",
                xs: "1fr",
                sm: "repeat(2, 1fr)",
              },
              pt: { xs: 2, sm: 2, md: 5, lg: 5 },
            }}
          >
            <ImageListItem
              sx={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                transition: "transform 0.5s ease-in-out",
                "&:hover img": { transform: "scale(1.1)" },
              }}
            >
              <img
                src="./source/application-management.jpg"
                alt="application-management"
              />
              {/* <Link to="/services/applicationmanage"> */}
              <ImageListItemBar
                title="Application Managed Services"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={"info about"}
                  >
                    <SendIcon />
                  </IconButton>
                }
              />
              {/* </Link> */}
            </ImageListItem>
            <ImageListItem
              sx={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                transition: "transform 0.5s ease-in-out",
                "&:hover img": { transform: "scale(1.1)" },
              }}
            >
              <img
                src="./source/cloud-transformation.jpg"
                alt="Infrastructure Management"
              />
              {/* <Link to="/services/infrastructure"> */}
              <ImageListItemBar
                title="Infrastructure Management"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={"info about"}
                  >
                    <SendIcon />
                  </IconButton>
                }
              />
              {/* </Link> */}
            </ImageListItem>
            <ImageListItem
              sx={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                transition: "transform 0.5s ease-in-out",
                "&:hover img": { transform: "scale(1.1)" },
              }}
            >
              <img
                src="./source/digital-marketing.jpg"
                alt="Digital Marketing"
              />
              {/* <Link to="/services/digitalmarketing"> */}
              <ImageListItemBar
                title="Digital Marketing"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={"info about"}
                  >
                    <SendIcon />
                  </IconButton>
                }
              />
              {/* </Link> */}
            </ImageListItem>
            <ImageListItem
              sx={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                transition: "transform 0.5s ease-in-out",
                "&:hover img": { transform: "scale(1.1)" },
              }}
            >
              <img src="./source/e-commerce.jpg" alt="E-Commerce" />
              {/* <Link to="/services/e-commerce"> */}
              <ImageListItemBar
                title="E-Commerce Services"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={"info about"}
                  >
                    <SendIcon />
                  </IconButton>
                }
              />
              {/* </Link> */}
            </ImageListItem>
          </Box>
        </Container>
      </Box>
      {/* -------------box end----------- */}
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.primary.light
              : theme.palette.primary.main,
          p: 6,
          color: (theme) => theme.palette.text.main,
          mt: 10,
        }}
      >
        {/* <Grid container spacing={2}>
     <Grid item lg={6} md={6} sm={12} xs={12}>
      <Paper sx={{ padding: "2%" }}>
       <Typography>
        If you believe something needs to exist, if it's something you want to
        use yourself, don't let anyone ever stop you from doing it.”...
       </Typography>
       <Typography sx={{ textAlign: "right", padding: "2%" }}>
        <em>Mahesh Waran, Founder & CEO, PIXElize Tech, IN</em>
       </Typography>
      </Paper>
     </Grid>
     <Grid item lg={6} md={6} sm={12} xs={12}>
      <Paper sx={{ padding: "2%" }}>
       <Typography>
        “You can get everything in life you want if you will just help enough
        other people get what they want.” ...
       </Typography>
       <Typography sx={{ textAlign: "right", padding: "2%" }}>
        <em>Bala Vignesh, Founder & CEO, PIXElize Tech, IN</em>
       </Typography>
      </Paper>
     </Grid>
    </Grid> */}
      </Box>
      <Box>
        <Container
          sx={{
            pt: { xs: 2, sm: 2, md: 5, lg: 5 },
          }}
        >
          <Typography
            variant="h4"
            color="text.disabled"
            // textAlign="center"
            sx={{ pt: { xs: 2, sm: 2, md: 5, lg: 5 } }}
          >
            Our <ColoredText>Digital Products</ColoredText>
            <Divider
              variant="left"
              style={{
                width: "28%",
                backgroundColor: "#0a89ff",
                paddingTop: "2px",
                margin: "3px",
              }}
            />
          </Typography>
          <br />
          <Typography
            variant="h6"
            // textAlign="center"
            fontSize={"30"}
            fontFamily={"Mitr,sans-serif"}
          >
            We offer a wide range of products and solutions designed for
            enterprises and small businesses across various industries.
            {/* <br />{" "} */}
          </Typography>
        </Container>

        <Container
          sx={{
            display: { md: "flex", lg: "flex", sm: "block", xs: "block" },
            gap: "30px",
          }}
        >
          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginLeft: { md: "10%", lg: "10%", sm: "5%", xs: "5%" },
              marginTop: { md: "15%", lg: "15%", sm: "5%", xs: "5%" },
              marginBottom: "5%",
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img
              src="./source/hospital-management.jpg"
              alt="hospital-management"
            />
            <ImageListItemBar
              title="Hospital Management Software"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>

          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginRight: "10%",
              marginTop: "5%",
              marginBottom: { md: "15%", lg: "15%", sm: "0%", xs: "0%" },
              marginLeft: { md: "0%", lg: "0%", sm: "5%", xs: "5%" },
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img src="./source/hrms-system.jpg" alt="hrms-system" />
            <ImageListItemBar
              title="Human Resource Management System"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        </Container>

        <Container
          sx={{
            display: { md: "flex", lg: "flex", sm: "block", xs: "block" },
            gap: "30px",
            mt: { md: "-200px", lg: "-200px", sm: "0%", xs: "0%" },
          }}
        >
          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginLeft: { md: "10%", lg: "10%", sm: "5%", xs: "5%" },
              marginTop: { md: "15%", lg: "15%", sm: "5%", xs: "5%" },
              marginBottom: "5%",
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img
              src="./source/textile-erp-solution.jpg"
              alt="textile-erp-solution"
            />
            <ImageListItemBar
              title="A Complete Textile ERP Solution"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>

          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginRight: "10%",
              marginTop: "5%",
              marginBottom: { md: "15%", lg: "15%", sm: "0%", xs: "0%" },
              marginLeft: { md: "0%", lg: "0%", sm: "5%", xs: "5%" },
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img
              src="./source/education-erp-solution.jpg"
              alt="Education ERP Solution"
            />
            <ImageListItemBar
              title="Education ERP Solution"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        </Container>
        <Container
          sx={{
            display: { md: "flex", lg: "flex", sm: "block", xs: "block" },
            gap: "30px",
            mt: { md: "-200px", lg: "-200px", sm: "0%", xs: "0%" },
          }}
        >
          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginLeft: { md: "10%", lg: "10%", sm: "5%", xs: "5%" },
              marginTop: { md: "15%", lg: "15%", sm: "5%", xs: "5%" },
              marginBottom: "5%",
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img src="./source/sales-service-crm.jpg" alt="sales-service-crm" />
            <ImageListItemBar
              title="Sales & Service CRM"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>

          <ImageListItem
            sx={{
              borderRadius: "20px",
              marginRight: "10%",
              marginTop: "5%",
              marginBottom: { md: "15%", lg: "15%", sm: "0%", xs: "0%" },
              marginLeft: { md: "0%", lg: "0%", sm: "5%", xs: "5%" },
              width: { md: "450px", lg: "450px", sm: "250px", xs: "250px" },
              height: {
                md: "350px !important",
                lg: "350px !important",
                sm: "200px !important",
                xs: "200px !important",
              },
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&:hover img": { transform: "scale(1.1)" },
            }}
          >
            <img src="./source/secondary-sales.jpg" alt="secondary-sales" />
            <ImageListItemBar
              title="Secondary Sales Management System"
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={"info about"}
                >
                  <SendIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        </Container>
        <br />
        <br />
      </Box>
      {/* -------------box end----------- */}
      {/* <>
    <Box style={{ marginBottom: "1.2%" }}>
     <Container>
      <Grid container spacing={2}>
       <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography variant="h4" color="text.disabled" textAlign="left">
         Our
         <ColoredText> 6-D Process</ColoredText>
         <Divider
          variant="left"
          style={{
           width: "25%",
           backgroundColor: "#0a89ff",
           paddingTop: "2px",
           margin: "3px",
          }}
         />
        </Typography>
        <br />
        <Typography
         variant="h6"
         textAlign="left"
         fontSize={"30"}
         fontFamily={"Mitr,sans-serif"}
        >
         Software Development Services
        </Typography>
       </Grid>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/about-bg-img.webp)`,
         backgroundPosition: "center",
         padding: "0px",
        }}
       >
        <Paper style={{ backgroundColor: "#00000078", color: "#ffffff" }}>
         <Typography variant="h5" padding={"2%"}>
          01{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Discover
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          Once a customer or stakeholder has requested a project,
          <br /> the first step of the SDLC is planning.
         </Typography>
        </Paper>
       </Grid>
      </Grid>
     </Container>
    </Box>
    <Box style={{ marginBottom: "1.2%" }}>
     <Container>
      <Grid container spacing={2}>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/ld2.webp)`,
         backgroundPosition: "right",
         padding: "0px",
        }}
       >
        <Paper
         style={{
          backgroundColor: "#00000078",
          color: "#ffffff",
          borderRadius: "0px",
         }}
        >
         <Typography variant="h5" padding={"2%"}>
          02{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Define
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          The next step is to understand the technical requirements of this
          project.
         </Typography>
        </Paper>
       </Grid>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/webdesign-company-in.webp)`,
         backgroundPosition: "right",
         padding: "0px",
        }}
       >
        <Paper
         style={{
          backgroundColor: "#00000078",
          color: "#ffffff",
          borderRadius: "0px",
         }}
        >
         <Typography variant="h5" padding={"2%"}>
          03{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Design
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          With the requirements in place, it’s time to start designing what this
          software will look like and how it will function.
         </Typography>
        </Paper>
       </Grid>
      </Grid>
     </Container>
    </Box>
    <Box style={{ marginBottom: "1.2%" }}>
     <Container>
      <Grid container spacing={2}>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/portfolio3.webp)`,
         backgroundPosition: "right",
         padding: "0px",
        }}
       >
        <Paper
         style={{
          backgroundColor: "#00000078",
          color: "#ffffff",
          borderRadius: "0px",
         }}
        >
         <Typography variant="h5" padding={"2%"}>
          04{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Develop
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          With everyone onboard with the software’s proposed functionality and
          design, it’s time to build it according to the requirements and SOW.
         </Typography>
        </Paper>
       </Grid>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/portfolio4-1.webp)`,
         backgroundPosition: "right",
         padding: "0px",
        }}
       >
        <Paper
         style={{
          backgroundColor: "#00000078",
          color: "#ffffff",
          borderRadius: "0px",
         }}
        >
         <Typography variant="h5" padding={"2%"}>
          05{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Deploy
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          Developing the software, most likely be simultaneously testing,
          tracking, and fixing bugs.
         </Typography>
        </Paper>
       </Grid>
      </Grid>
     </Container>
    </Box>
    <Box style={{ marginBottom: "1.2%" }}>
     <Container>
      <Grid container spacing={2}>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundImage: `url(./source/application-development-maintenance.jpg)`,
         backgroundPosition: "right",
         padding: "0px",
        }}
       >
        <Paper
         style={{
          backgroundColor: "#00000078",
          color: "#ffffff",
          borderRadius: "0px",
         }}
        >
         <Typography variant="h5" padding={"2%"}>
          06{" "}
         </Typography>
         <Typography variant="h5" padding={"2%"}>
          Deliver
         </Typography>
         <Typography variant="body1" padding={"2% 2% 10% 2%"}>
          With the heavy lifting (and coding) out of the way, it’s time to
          launch software.
         </Typography>
        </Paper>
       </Grid>
       <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{
         backgroundColor: "#0a89ff",
         borderRadius: "0px",
         color: "#ffffff",
        }}
       >
        <Typography variant="h5" padding={"2%"}>
         Hear From Software Development Experts{" "}
        </Typography>
        <Typography variant="h4" padding={"2%"}>
         <Link to="/contact" style={{ textDecoration: "none" }}>
          <Typography
           color="white"
           fontSize="25px"
           backgroundColor="#ff5722"
           borderRadius="5px"
           width="30%"
           padding="5px"
           textAlign="center"
          >
           Contact Us
          </Typography>
         </Link>
        </Typography>
       </Grid>
      </Grid>
     </Container>
     <br />
     <br />
    </Box>
   </> */}
      {/* -------------box end----------- */}
    </>
  );
};
export default HomeContent;

import {
 Accordion,
 AccordionDetails,
 AccordionSummary,
 Box,
 Button,
 Container,
 Divider,
 Grid,
 Paper,
 Typography,
} from "@mui/material";
import React, { useState } from "react";

import AssistWalkerIcon from "@mui/icons-material/AssistWalker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DvrIcon from "@mui/icons-material/Dvr";
import HubIcon from '@mui/icons-material/Hub';
import StorageIcon from '@mui/icons-material/Storage';
import DnsIcon from '@mui/icons-material/Dns';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import CommonContactFrom from "./CommonContactFrom";

const Infrastructure = () => {
 const [expanded, setExpanded] = useState(false);
 const onChangeHandler = (Acc) => (event, isExpanded) => {
  setExpanded(isExpanded ? Acc : false);
 };
 return (
  <>
   <Box
    style={{
     backgroundImage: `url(../source/infrastructure.jpg)`,
     padding: "0px",
     backgroundRepeat: "no-repeat",
    }}
   >
    <Typography
     variant="h5"
     sx={{ padding: "4% 6% 0% 6%", color: "white", marginBottom: "1%" }}
    >
     Infrastructure Management & <br /> Security Services
     <Divider
      width="5%"
      sx={{ paddingTop: "2px", backgroundColor: "white", marginTop: "1%" }}
     />
    </Typography>
    <Typography
     variant="body1"
     sx={{ fontWeight: "300", color: "white", padding: "0% 6% 6% 6%" }}
    >
     We minimize your downtime with
     <br />
     our reliable technical experts and <br />
     IT Infrastructure management.
    </Typography>
    <Box style={{ backgroundColor: "#0a89ff" }}>
     <Typography
      style={{
       padding: "2%",
       color: "white",
       marginTop: "2%",
       fontWeight: "300",
      }}
     >
      We can produce sustainable value to your organization by providing
      rigorous service methodologies, best practices, innovative ideas and
      industry expertise through both onshore and offshore services models. With
      our proficient resources, we work round the clock taking complete
      responsibilities in maintaining client applications and technology
      infrastructure.
     </Typography>
    </Box>
   </Box>
   {/* ------box end */}
   <Box style={{ paddingTop: "3%", paddingBottom: "3%" }}>
    <Container>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography
        variant="body1"
        sx={{ textAlign: "justify", fontWeight: "200" }}
       >
        We proficiently carries the burden of managing the infrastructure
        internally as this needs 24x7x365 days of monitoring and support. With
        our in-house team we can reduce your cost a lot and ease the ruckus when
        there are more escalations at a time. With our expertise, companies can
        utilize the best-in-class IMS that works seamlessly with the current
        trends of multi-cloud and hybrid deployment strategy.
       </Typography>
       <Typography
        variant="body1"
        sx={{ textAlign: "justify", fontWeight: "200", paddingTop: "2%" }}
       >
        Starting from configuring new infrastructure components to migrating the
        same infrastructure to the cloud is made easy. We monitor and audit
        infrastructure security and optimize resource consumption with our
        proven methods performed with various clients across the globe.
       </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       {" "}
       <img
        src="../source/infrastructureserv.png"
        alt=""
        style={{ paddingLeft: "25%", width: "45%" }}
       />
      </Grid>
     </Grid>
    </Container>
   </Box>

   {/* ------box end */}
   <Box style={{ paddingTop: "3%", paddingBottom: "3%" }}>
    <Typography variant="h4" style={{ textAlign: "center" }}>
     Our Services include
    </Typography>
    <Container style={{ paddingTop: "3%" }}>
     <Grid container>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <DvrIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Enterprise systems
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <HubIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Network security
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <StorageIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Server Maintenance
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <DnsIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Data storages
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <FilterDramaIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Cloud Management
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <Diversity3Icon  />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Consulting
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <TroubleshootIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Troubleshooting
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <LegendToggleIcon  />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Monitoring 24/7
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <AssistWalkerIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Help Desk
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <ManageHistoryIcon/>
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Management
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <CloudSyncIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Cloud Migration
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <AssuredWorkloadIcon  />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Compliance
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <VpnLockIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.2rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Cybersecurity
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <Box
    style={{
     backgroundImage: `url(../source/infrastructurefull.jpg)`,
     backgroundSize: "cover",
     backgroundAttachment: "fixed",
     backgroundPosition: "center cnter",
    }}
   >
    <Typography variant="h4" sx={{ padding: "5%", color: "white" }}>
     Build the future <br />
     into your business
    </Typography>
   </Box>
   {/* ------box end */}
   <Box sx={{ backgroundColor: "#f0efef", paddingTop: "5%" }}>
    <Container>
     <Typography variant="h4" sx={{ textAlign: "center" }}>
      Service Highlights
     </Typography>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "5%" }}>
       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc1"}
         onChange={onChangeHandler("Acc1")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc1"
          id="Acc1"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc1" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>01.</span> Application Management
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          With our in-house technology expertise, we provide application
          analytics, functional and technical app support and remote performance
          management on the go. We work with your IT team and provide support
          anytime needed.
         </AccordionDetails>
        </Accordion>
       </Container>

       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc2"}
         onChange={onChangeHandler("Acc2")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc2"
          id="Acc2"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc2" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>02.</span> ECommerce Solutions
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          We provides complete management services starting with planning,
          deployment and configuration of website be it an ecommerce or any
          other services. We provide 24/7 performance monitoring and support
          services. We are well versed with security audit services.
         </AccordionDetails>
        </Accordion>
       </Container>

       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc3"}
         onChange={onChangeHandler("Acc3")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc3"
          id="Acc3"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc3" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>03.</span> Enterprise Systems
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          Our supports with integration, maintenance, support and migration
          services for any Data centers, directory services, email and VoIP
          Systems, intranet and collaboration solutions and ERP/ CRM systems.
          You name it and we comes up with the best-in-class solution.
         </AccordionDetails>
        </Accordion>
       </Container>

       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc4"}
         onChange={onChangeHandler("Acc4")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc4"
          id="Acc4"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc4" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>04. </span> Network and Security
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          With Our proven methods and years of experience we assist our clients
          with Internet access and remote access configuration, LAN and VPN
          integration and maintenance and support, failover network design, ,
          Network discovery, Bandwidth management, IDS/IPS and firewall,
          antiviruses integration, implementation, configuration, maintenance,
          support and upgradation.
         </AccordionDetails>
        </Accordion>
       </Container>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "5%" }}>
       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc5"}
         onChange={onChangeHandler("Acc5")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc5"
          id="Acc5"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc5" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>05.</span> Servers
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          Our provides 24/7 server monitoring, updating and hardening, Security
          monitoring and audit, Performance optimization, Backup management,
          Emergency patching and Disaster recovery. Our experienced resources
          always monitor and deliver the best services in the market..
         </AccordionDetails>
        </Accordion>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc6"}
         onChange={onChangeHandler("Acc6")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc6"
          id="Acc6"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc6" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>06.</span> Data Storage and
           Databases
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          With our top-notch technical expertise, we help companies with Design
          and implementation, Configuration and administration, Capacity
          management and monitoring, Performance tuning and disaster recovery
          for Data storage and database.
         </AccordionDetails>
        </Accordion>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Accordion
         expanded={expanded === "Acc7"}
         onChange={onChangeHandler("Acc7")}
        >
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Acc7"
          id="Acc7"
         >
          <Typography
           variant="h6"
           sx={{ color: expanded === "Acc7" ? "#0a89ff" : "inherit" }}
          >
           <span style={{ color: "#0a89ff" }}>07.</span> Cloud Services
          </Typography>
         </AccordionSummary>
         <Divider />
         <AccordionDetails>
          Our partners with best-in-class cloud service providers that work
          seamlessly and prove support 24/7. We provide Private, public and
          hybrid cloud infrastructure design and integration, administration,
          maintenance, security audit and support.
         </AccordionDetails>
        </Accordion>
       </Container>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <Box sx={{ padding: "5%", backgroundColor: "#f9fcff" }}>
    <Container>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography variant="h4">Portfolio </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "end" }}>
       <Button variant="outlined">View web Portfolio</Button>
       <br />
       <span>CommingSoon....</span>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <CommonContactFrom />
  </>
 );
};

export default Infrastructure;

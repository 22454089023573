import React from "react";
import {
 Avatar,
 Box,
 Button,
 Container,
 Grid,
 Paper,
 Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ReactWhatsapp from "react-whatsapp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CommonContactFrom from "./CommonContactFrom";

const Ecommerce = () => {
 return (
  <>
   <Box
    style={{
     backgroundColor: "white",
     backgroundImage: `url(../source/officespace.jpg)`,
     backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     backgroundSize: "cover",
    }}
   >
    <Box
     style={{
      backgroundColor: "transparent",
      backgroundImage: "linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 24%)",
      opacity: "0.9",
     }}
    >
     <Container>
      <Grid container>
       <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography variant="h5" sx={{ paddingTop: "15%" }}>
         Amazing Feature with Affordable Price
        </Typography>
        <Typography variant="h4" sx={{ paddingTop: "6%" }}>
         Create Premium eCommerce Website Development
        </Typography>
        <Typography variant="h6" sx={{ paddingTop: "4%", fontWeight: "300" }}>
         Diverse of possibilities to take your online business to the next level
        </Typography>
        <ReactWhatsapp
         number="+919344335333"
         message="Hello World!!!"
         style={{ border: "none", background: "none", paddingTop: "10%" }}
        >
         <Button variant="contained" endIcon={<WhatsAppIcon />}>
          Let's Talk
         </Button>
        </ReactWhatsapp>
       </Grid>
       <Grid item lg={6} md={6} sm={12} xs={12}>
        <img src="../source/eCommerceweb.jpg" alt="" width={"100%"} />
       </Grid>
      </Grid>
     </Container>
    </Box>
   </Box>
   {/* -----------------Box end-------------- */}
   <Box>
    <Container>
     <Grid container sx={{ paddingTop: "10%" }}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography variant="h4" sx={{ paddingTop: "3%", color: "#0a89ff" }}>
        BEST E-COMMERCE <br /> WEBSITE DESIGN & <br /> DEVELOPMENT IN Pixelize
       </Typography>
       <Typography variant="h4" sx={{ color: "#948f8f" }}>
        TO EASE YOU BOOST ONLINE <br /> STORE ENGAGEMENT AND <br />
        INTENSIFY BRAND <br />
        EXPOSURE.
       </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <img
        src="../source/ecommerce-webs.jpg"
        alt=""
        style={{ width: "100%" }}
       />
      </Grid>
     </Grid>
    </Container>
    <Container sx={{ paddingTop: "5%" }}>
     <Typography
      variant="h5"
      sx={{ fontWeight: "300", fontSize: "1.1rem", paddingTop: "2%" }}
     >
      Pixelize is the well experienced eCommerce website development company,
      offers the websites with all possible web solutions.
      <br />
     </Typography>
     <Typography
      variant="h5"
      sx={{ fontWeight: "300", fontSize: "1.1rem", paddingTop: "2%" }}
     >
      Our eCommerce web designers build the websites as per the client
      requirement and we delivers the website with free basic SEO services.
      <br />
     </Typography>
     <Typography
      variant="h5"
      sx={{ fontWeight: "300", fontSize: "1.1rem", paddingTop: "2%" }}
     >
      Pixelize is the professional web development firm delivers the attractive
      website development for their clients.
      <br />
     </Typography>
     <Typography
      variant="h5"
      sx={{ fontWeight: "300", fontSize: "1.1rem", paddingTop: "2%" }}
     >
      Through this customer can get the good online presence and improves their
      business performance. Web development is art of creating a Website.
      <br />
     </Typography>
     <Typography
      variant="h5"
      sx={{ fontWeight: "300", fontSize: "1.1rem", paddingTop: "2%" }}
     >
      In addition provide several offers for the startup companies to grow their
      business in online. Because startup companies has lot of budget problem so
      they skip website development to bring their business online.
     </Typography>
    </Container>
   </Box>
   {/* -----------------Box end-------------- */}
   <Box>
    <Container>
     <Grid container>
      <Grid item lg={12} md={12} style={{ padding: "10%" }}>
       <img src="../source/ecomm.jpg" alt="" style={{ width: "100%" }} />
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* -----------------Box end-------------- */}
   <Box style={{ backgroundColor: "#0a89ff", padding: "3%" }}>
    <Container>
     <Grid container>
      <Grid item lg={10} md={10}>
       <Typography sx={{ fontWeight: "600", color: "white" }} variant="h5">
        Hear From eCommerce Web Development Experts
       </Typography>
      </Grid>
      <Grid item lg={2} md={2}>
       <ReactWhatsapp
        number="+919344335333"
        message="Hello World!!!"
        style={{ border: "none", background: "none", paddingTop: "10%" }}
       >
        <Button variant="contained" endIcon={<WhatsAppIcon />}>
         Let's Talk
        </Button>
       </ReactWhatsapp>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* -----------------Box end-------------- */}
   <Box>
    <Container>
     <Grid container sx={{ padding: "5%", mx: "auto" }}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
       <Paper elevation={0} sx={{ textAlign: "center",paddingTop:'10%' }}>
        <Avatar sx={{ marginLeft: "50%" }}>
         <CurrencyRupeeIcon />
        </Avatar>
        <Typography variant="h5">Affordable Prices</Typography>
        <Typography variant="h6" sx={{ fontWeight: "300" }}>
         We Provide eCommerce website development at affordable Prices with
         premium quality
        </Typography>
       </Paper>
       <Paper elevation={0} sx={{ textAlign: "center",paddingTop:'10%' }}>
        <Avatar sx={{ marginLeft: "50%" }}>
         <CurrencyRupeeIcon />
        </Avatar>
        <Typography variant="h5">Quality Content</Typography>
        <Typography variant="h6" sx={{ fontWeight: "300" }}>
         We ensure that our website have quality content relevant to your
         business.
        </Typography>
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
       <Paper elevation={0} sx={{ textAlign: "center",paddingTop:'10%' }}>
        <Avatar sx={{ marginLeft: "50%" }}>
         <DesignServicesIcon />
        </Avatar>
        <Typography variant="h5">Trendy Design</Typography>
        <Typography variant="h6" sx={{ fontWeight: "300" }}>
         We deliver your website with Trendy web design that attract your target
         audience
        </Typography>
       </Paper>
       <Paper elevation={0} sx={{ textAlign: "center",paddingTop:'10%' }}>
        <Avatar sx={{ marginLeft: "50%" }}>
         <DesignServicesIcon />
        </Avatar>
        <Typography variant="h5">Easy Navigation</Typography>
        <Typography variant="h6" sx={{ fontWeight: "300" }}>
         The website deliver by our team 100% easy to use & navigate.
        </Typography>
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
       <img src="../source/eCommerceweb.jpg" alt="" width={'100%'}/>
      </Grid>
     </Grid>
    </Container>
   </Box>
   <CommonContactFrom/>
  </>
 );
};

export default Ecommerce;

import {
 Box,
 Button,
 Container,
 Grid,
 Paper,
 Typography,
} from "@mui/material";
import React from "react";
import DvrIcon from "@mui/icons-material/Dvr";
import LanIcon from "@mui/icons-material/Lan";
import BugReportIcon from "@mui/icons-material/BugReport";
import EngineeringIcon from "@mui/icons-material/Engineering";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AssistWalkerIcon from "@mui/icons-material/AssistWalker";
import CommonContactFrom from "./CommonContactFrom";

const ApplicationManage = () => {
 return (
  <>
   <Box
    style={{
     backgroundImage: `url(../source/manage-img.jpg)`,
     backgroundPosition: "center",
     padding: "0px",
    }}
   >
    <Typography variant="h5" style={{ padding: "6%", color: "white" }}>
     NextGen Application <br />
     Managed Services (AMS)
    </Typography>
    <Box style={{ backgroundColor: "#0a89ff" }}>
     <Typography
      style={{
       padding: "2%",
       color: "white",
       marginTop: "2%",
       fontWeight: "300",
      }}
     >
      We can produce sustainable value to your organization by providing
      rigorous service methodologies, best practices, innovative ideas and
      industry expertise through both onshore and offshore services models. With
      our proficient resources, we work round the clock taking complete
      responsibilities in maintaining client applications and technology
      infrastructure.
     </Typography>
    </Box>
   </Box>

   {/* ------box end */}
   <Box style={{ paddingTop: "3%" }}>
    <Typography variant="h4" style={{ textAlign: "center" }}>
     Our Services include
    </Typography>
    <Container style={{ paddingTop: "3%" }}>
     <Grid container>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <DvrIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Monitoring
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <LanIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Optimizing
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <BugReportIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Testing
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <EngineeringIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Fixing
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <TroubleshootIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         Troubleshooting
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ padding: "2%" }}>
       <Paper sx={{ padding: "8%", position: "relative" }}>
        <AssistWalkerIcon />
        <Typography
         sx={{
          paddingLeft: "7%",
          fontSize: "1.4rem",
          display: "inline",
          color: "#000000",
          "&:hover": {
           color: "#0a89ff",
          },
         }}
        >
         training services
        </Typography>
        <img
         src="../source/screen-arw.png"
         alt=""
         style={{
          position: "absolute",
          right: "-7px",
          height: "30px",
          top: "10px",
         }}
        />
       </Paper>
      </Grid>
     </Grid>
    </Container>
   </Box>

   {/* ------box end */}
   <Box sx={{ backgroundColor: "#f0efef", marginTop: "5%", paddingTop: "3%" }}>
    <Container>
     <Typography variant="h4" sx={{ textAlign: "center" }}>
      Service Highlights
     </Typography>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "5%" }}>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}>01.</span> Application Management
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         With our in-house technology expertise, we provide application
         analytics, functional and technical app support and remote performance
         management on the go. We work with your IT team and provide support
         anytime needed.
        </Typography>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}> 02.</span> Website and eCommerce
         Solutions
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         We provides complete management services starting with planning,
         deployment and configuration of website be it an ecommerce or any other
         services. We provide 24/7 performance monitoring and support services.
         We are well versed with security audit services.
        </Typography>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}> 03.</span> Enterprise Systems
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         By having one website for all device screens, you can save the cost of
         two websites for different display screens, and it also avoids the
         inconvenience in maintaining both websites simultaneously.We supports
         with integration, maintenance, support and migration services for any
         Data centers, directory services, email and VoIP Systems, intranet and
         collaboration solutions and ERP/ CRM systems. You name it and Pixelize comes
         up with the best-in-class solution.
        </Typography>
       </Container>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "5%" }}>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}> 04.</span> Network and Security
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         With We proven methods and years of experience we assist our clients
         with Internet access and remote access configuration, LAN and VPN
         integration and maintenance and support, failover network design, ,
         Network discovery, Bandwidth management, IDS/IPS and firewall,
         antiviruses integration, implementation, configuration, maintenance,
         support and upgradation.
        </Typography>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}> 05. </span>Application Enhancements
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         Be it a custom build or Commercial off the shelf application, Pixelize has
         an effective process for handling small changes, fixes and enhancements
         around your application. Our experienced resources analyze the impact
         carefully and make changes based on regulations and compliance then
         improve the performance of the application.
        </Typography>
       </Container>
       <Container sx={{ padding: "5%" }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
         <span style={{ color: "#0a89ff" }}> 06. </span>Change Management and
         Training Services
        </Typography>
        <hr></hr>
        <Typography variant="body1" sx={{ fontWeight: "300" }}>
         we provides a persuasive approach to upgrade your systems with a
         comprehensive change management plan that includes but not limited to
         full test coverage, risk analysis and after live review and a detailed
         training services with progressive guides, offshore and onshore
         trainings and workshops. We ensures that your stakeholders are
         thorough with the updated system versions or functional extensions and
         know how to use them an efficient and productive way.
        </Typography>
       </Container>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <Box sx={{ padding: "5%", backgroundColor: "#f9fcff" }}>
    <Container>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <Typography variant="h4">Portfolio </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "end" }}>
       <Button variant="outlined">View web Portfolio</Button>
       <br />
       <span>CommingSoon....</span>
      </Grid>
     </Grid>
    </Container>
   </Box>
   {/* ------box end */}
   <CommonContactFrom/>
  </>
 );
};
export default ApplicationManage;

import { Grid, Link, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Facebook, Instagram, Twitter } from '@mui/icons-material/';
import React from 'react'

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Pixelize Technologies
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Pixelize Technologies, a reputed design and development firm with offices in India, 
                offers world-class services. Our expertise enables businesses to generate more leads, 
                increase sales, and achieve their objectives.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              210/3, Kovai Road, Kundadam
              Dharapuram,Tirupur,
              Tamil Nadu–638702
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: pixelizetech@gmail.com
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +91 93443 35333 /  +91 90952 66280
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/" color="inherit">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit">
              <Twitter />
            </Link>
          </Grid> */}
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit">
              Pixelize Technologies
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
export default Footer
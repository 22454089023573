import React from 'react'
import HomeContent from './HomeContent'

const Home = () => {
  return (
    <>
      <HomeContent />
    </>
  )
}
export default Home
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import { ThemeProvider, createTheme } from '@mui/material'
import Aboutus from './components/Aboutus'
import Contactus from './components/Contactus'
import Header from './components/Header'
import Footer from './components/Footer'
import ApplicationManage from './components/ApplicationManage'
import Infrastructure from './components/Infrastructure'
import Sco from './components/Sco'
import Ecommerce from './components/Ecommerce'
import Careers from './components/Careers'


const zqubeTheme = createTheme({
  palette: {
    primary: {
      main: '#1f1e5a'
    }
  },
  typography: {
    fontFamily: 'Ubuntu',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400,
  }
})

const App = () => {

  //const theme = useTheme()

  //console.log(theme, 'themetheme');
  return (
    <>
      <ThemeProvider theme={zqubeTheme}>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<Aboutus />} />
          {/* <Route path='/contact' element={<Contactus />} /> */}
          <Route path='/services/applicationmanage/' element={<ApplicationManage />} />
          <Route path='/services/infrastructure/' element={<Infrastructure />} />
          <Route path='/services/digitalmarketing/' element={<Sco />} />
          <Route path='/services/e-commerce/' element={<Ecommerce />} />
          {/* <Route path='/career' element={<Careers />} /> */}
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  )
}
export default App